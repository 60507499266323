<script>
import moment from 'moment'
//import { ref } from 'vue';
const accountSid = 'AC6e18670ebdb21fed26fb3312ebbe7726';
const authToken = '2957edfe27b9d2a60333a141d4c1eba1';
// const client = require('twilio')(accountSid, authToken);]
import Multiselect from 'vue-multiselect'
import axios from 'axios';
import invita from '../assets/invitados.json'
export default {
    components: {
        Multiselect
    },
    data() {
        return {
            dateTarget: moment('2023-12-06T17:00:00'),
            loadingData: true,
            loadingSend: false,
            timeLeft: null,
            userAgent: false,
            dark: false,
            key: null,
            activeMessage: false,
            selectedInvitado: null,
            bookingDisabled: false,
            /* invitados: {
                cecilia: {
                    name: 'Cecilia Serrano y Fam.',
                    assistants: 4,
                },
                abuelos_mama: {
                    name: 'Fam. Castro Benavides',
                    assistants: 5,
                },
                nelson: {
                    name: 'Fam. Serrano Castro',
                    assistants: 2,
                },
                karla: {
                    name: 'Karla Serrano y Fernando Alfaro',
                    assistants: 2,
                },
                salvador: {
                    name: 'Fam. Serrano Alvarenga',
                    assistants: 6,
                },
                magda: {
                    name: 'Fam. Blanco Castro',
                    assistants: 4,
                },
                abuela_rosa: {
                    name: 'Fam. Rodríguez Euceda',
                    assistants: 4,
                },
                didier: {
                    name: 'Fam. Alvarez',
                    assistants: 3,
                },
                jesus: {
                    name: 'Fam. Lopez',
                    assistants: 2,
                },
                rigo: {
                    name: 'Fam. Rodríguez Duran',
                    assistants: 2,
                },
                antonio: {
                    name: 'Sr. Antonio Rodríguez y esposa',
                    assistants: 2,
                },
                pablo: {
                    name: 'Fam. Rodríguez Cuadra',
                    assistants: 6,
                },
                online: {
                    name: 'Familia y Amigos',
                    assistants: 'ilimit',
                },
            }, */
            invitados: invita,
            optionsConfirmation: [
                {
                    value: 'SI_ASISTIRE',
                    label: 'SI ASISTIRE'
                },
                {
                    value: 'NO_ASISTIRE',
                    label: 'NO ASISTIRE'
                }
            ],
            form: {
                confirmation: null,
                assistants: null,
                messages: '',
            },
            optionsAssistants: [],
        }
    },
    computed: {
        days() {
            if(this.timeLeft){
                return this.timeLeft.days();
            }
        },
        hours(){
            if(this.timeLeft){
                return this.timeLeft.hours();
            }
        },
        minutes(){
            if(this.timeLeft){
                return this.timeLeft.minutes();
            }
        },
        seconds() {
            if(this.timeLeft){
                return this.timeLeft.seconds();
            }
        },
        styleLogo() {
            return this.userAgent && !this.dark ? 'text-color' : 'text-color';
        },
        styleDark() {
            return this.userAgent && !this.dark ? 'fondo-none' : '';
        }
    },
    watch:{
        'form.confirmation'(nVal) {
            this.setDisabled(nVal);
        }
    },
    mounted(){
        this.updateTime();
        this.loadingData = true;
        setInterval(this.updateTime, 1000);
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            // El sistema está en modo oscuro
            this.dark = true;
            console.log('Modo oscuro');
        } else {
            // El sistema está en modo claro
            console.log('Modo claro');
        }
        if (navigator.userAgent.includes("Samsung")) {
            // El dispositivo es un Samsung
            this.userAgent = true;
            console.log('Es un dispositivo Samsung');
        } else {
            // No es un dispositivo Samsung
            console.log('No es un dispositivo Samsung');
        }
        setTimeout(() => {
            const localData = localStorage.getItem('invitado');
            if(localData){
                this.selectedInvitado = JSON.parse(localData);
                this.arrayNumero(this.selectedInvitado?.assistants);
            }else{
                this.key = this.$route.params.key;
                console.log(this.invitados[this.key]);
                this.selectedInvitado = this.invitados[this.key];
                if(this.selectedInvitado){
                    localStorage.setItem('invitado', JSON.stringify(this.invitados[this.key]));
                }
                this.arrayNumero(this.selectedInvitado?.assistants);
            }
            this.loadingData = false;
        }, 200);
    },
    methods: {
        setDisabled(confirm){
            if(confirm && confirm.value == 'NO_ASISTIRE'){
                console.log('cambie', confirm);
                this.bookingDisabled = true;
                this.form.assistants = null;
            }else{
                this.bookingDisabled = false;
            }
        },
        updateTime() {
            const currentDate = moment();
            this.timeLeft = moment.duration(this.dateTarget.diff(currentDate));
        },
        range(start, end) {
            // Crea un rango numérico desde 'start' hasta 'end'
            return Array.from({ length: end - start + 1 }, (_, i) => start + i);
        },
        sendMessage(){
            this.loadingSend = true;
            this.activeMessage = false;
            if(!this.form.confirmation){
                this.activeMessage = true;
                return
            }
            if(!this.bookingDisabled && !this.form.assistants){
                this.activeMessage = true;
                return;
            }
            let assistants = this.form.assistants ? this.form.assistants.value : 0;
            const body = `Hola estimados novios\nSoy: ${this.selectedInvitado.name} \nConfirmación de mi invitación: ${this.form.confirmation.label}\nAsisteremos: ${assistants} invitados\n${this.form.messages}`
            const postData = new URLSearchParams();
            postData.append('To', 'whatsapp:+50375119202');
            postData.append('From', 'whatsapp:+14155238886');
            postData.append('Body', body);
            // Define las opciones de la solicitud
            const axiosConfig = {
            method: 'post',
            url: 'https://api.twilio.com/2010-04-01/Accounts/AC6e18670ebdb21fed26fb3312ebbe7726/Messages.json',
            data: postData,
            auth: {
                username: 'AC6e18670ebdb21fed26fb3312ebbe7726',
                password: '2957edfe27b9d2a60333a141d4c1eba1'
            },
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
            };
            axios(axiosConfig)
                .then(response => {
                    console.log(response.data);
                    this.selectedInvitado.confirm = true;
                    localStorage.setItem('invitado', JSON.stringify(this.selectedInvitado));
                    this.resetForm();
                })
                .catch(error => {
                    console.error(error);
                });
        },
        arrayNumero(assistants){
            for (let i = 1; i <= assistants; i++) {
                this.optionsAssistants.push({
                    value: i,
                    label: `${i} ${i>1?'invitados':'invitado'}`
                });
            }
        },
        resetForm(){
            this.form = {
                confirmation: null,
                assistants: null,
            }
        }
    }
}
</script>

<template>
    <div>
        <div class="invitation-logo fondo8 flex justify-center flex-col" :class="styleDark">
            <div class="text-center flex items-center w-full justify-center">
                <img alt="Invitation logo" class="logo w-[20%] md:w-[10%] animate__animated animate__heartBeat animate__repeat" src="../assets/logo.png">
            </div>
            <h1 class="title-logo text-5xl mt-[-10px] animate__animated animate__backInLeft animate__delay-0.25s tracking-wider" :class="styleLogo">Rosa & Jasson</h1>
            <span style="letter-spacing:0em;" class="text-date text-2xl" :class="styleLogo" v-if="selectedInvitado">06 • 12 • 2023</span>
        </div>
        <div class="invitation-crono fondo5 flex justify-center flex-col" :class="styleDark">
            <div class="text-center flex mt-3 items-center w-full justify-center">
                <div class="line"></div>
            </div>
            <h1 class="title-logo text-4xl mt-2 animate__animated animate__tada animate__delay-0.25s" :class="styleLogo">Nuestra Boda
            </h1>
            <div class="cronometro animate__animated animate__pulse" v-if="selectedInvitado">
                <div class="tiempo">
                    <div class="dia">{{ days }} <span class="text-sm">Días</span></div>
                    <div> : </div>
                    <div class="hora">{{ hours }} <span class="text-sm">Horas</span></div>
                    <div> : </div>
                    <div class="minuto">{{ minutes }} <span class="text-sm">Min</span></div>
                    <div> : </div>
                    <div class="segundo">{{ seconds }} <span class="text-sm">Seg</span></div>
                </div>
            </div>
        </div>
        <div class="invitation-phrase fondo7 flex justify-center flex-col" :class="styleDark" v-if="selectedInvitado">
            <div class="text-center flex mt-3 items-center w-full justify-center">
                <p class="text-3xl text-center w-4/5 md:w-1/3 text-color text-font2 m-4 tracking-wider" :class="styleLogo">Que cada amanecer nos encuentre más cerca, más fuertes y más enamorados que nunca, superando juntos las pruebas de la vida!.</p>
            </div>
        </div>
        <div class="invitation-ceremonia fondo4 flex justify-center flex-col" :class="styleDark" v-if="selectedInvitado">
            <div class="text-center flex flex-col mt-3 items-center w-full justify-center">
                <img src="../assets/anillos.png" class="w-28" />
                <p class="text-3xl text-center w-4/5 md:w-1/2 text-color text-font2 m-1" :class="styleLogo">Ceremonia Civil</p>
                <p class="text-3xl text-center w-4/5 md:w-1/2 text-color text-font2 m-1" :class="styleLogo">5:00 pm</p>
                <p class="text-3xl text-center w-4/5 md:w-1/2 text-color text-font2 m-1" :class="styleLogo">Bellanova - Jardin</p>
                <p class="text-2xl text-center w-4/5 md:w-1/3 text-color text-font2 m-1 tracking-wider" :class="styleLogo">Carretera panamericana, salida a la Unión, entrada a col. Carrillo #1 , San Miguel, El Salvador</p>
                <a class="btn-mapa" href="https://maps.app.goo.gl/pQd2oNNiYk8A8Wr1A" target="_blank">
                    Ver Mapa
                </a>
            </div>
        </div>
        <div class="invitation-confirma fondo2 flex justify-center flex-col" :class="styleDark" v-if="selectedInvitado">
            <div class="text-center flex flex-col mt-3 items-center w-full justify-center">
                <img src="../assets/confirma.png" class="w-52" />
                <p class="text-4xl md:text-5xl text-center w-4/5 md:w-1/2 text-color text-font2 m-1" :class="styleLogo">Confirma tu Asistencia</p>
                <p class="text-2xl md:text-5xl text-center w-4/5 md:w-1/2 font-bold tracking-widest text-color text-font2 m-1" :class="styleLogo"  v-if="!selectedInvitado.confirm">Reservado para: {{ selectedInvitado.assistants != 'unlimited' ? selectedInvitado.assistants : 'Familia y amigos'}} {{ selectedInvitado.assistants == 1 ? 'invitado' : 'invitados' }} </p>
                <p class="text-2xl md:text-3xl text-center w-4/5 md:w-1/2 text-color text-font2 m-1 tracking-wider" :class="styleLogo" v-if="!selectedInvitado.confirm && selectedInvitado.assistants != 'unlimited'">{{ selectedInvitado.name }} <br/> Agredecemos confirmar asistencia antes del 27 de noviembre.</p>
                <p class="text-2xl md:text-3xl text-center w-4/5 md:w-1/2 text-color text-font2 m-1 tracking-wider" :class="styleLogo" v-if="selectedInvitado.confirm && selectedInvitado.assistants != 'unlimited'">{{ selectedInvitado.name }} <br/> Gracias por confirmar tu reserva.</p>
                <div class="w-3/4 md:w-1/2 m-2">
                    <form v-if="!selectedInvitado.confirm && selectedInvitado.assistants != 'unlimited'">
                        <div class="flex flex-col md:flex-row gap-4 mb-4">
                            <div class="w-full md:w-1/2">
                                <span class="text-left text-font text-color">Confirmar asistencia</span>
                                <multiselect
                                    v-model="form.confirmation"
                                    :options="optionsConfirmation"
                                    input-class="form-control border rounded text-font text-color"
                                    placeholder="confirma tu asistencia"
                                    label="label"
                                    :clear-on-select="false"
                                    :close-on-select="true"

                                >
                                </multiselect>
                            </div>
                            <div class="w-full md:w-1/2">
                                <span class="text-left text-font text-color">Reservo para:</span>
                                <multiselect
                                    v-model="form.assistants"
                                    :options="optionsAssistants"
                                    :placeholder="`Confirma tu reserva y la de tus acompañantes (si aplica)`"
                                    label="label"
                                    :disabled="bookingDisabled"
                                >
                                </multiselect>
                            </div>
                            <div class="w-full" v-if="activeMessage">
                                <label class="text-left text-font text-red-500">Por favor completa el formulario de confirmación de asistencia</label>
                            </div>
                        </div>
                        <div class="flex flex-row gap-4 justify-end" v-if="!loadingSend">
                            <button type="button" class="btn-mapa w-full text-font" @click="sendMessage()">Enviar confirmación</button>
                        </div>
                    </form>
                    <div v-if="selectedInvitado.assistants == 'unlimited'">
                        <div class="flex flex-row gap-4 justify-end">
                            <a class="btn-mapa w-full text-font" target="_blank" href="https://meet.google.com/vmo-jgwa-prp">Ver boda en vivo</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="invitation-confirma fondo2 flex justify-center flex-col" :class="styleDark" v-if="!selectedInvitado & !loadingData">
            <div class="text-center flex flex-col mt-3 items-center w-full justify-center">
                <img src="../assets/confirma.png" class="w-52" />
                <p class="text-5xl text-center w-4/5 md:w-1/3 text-color text-font2 m-4 tracking-wider" :class="styleLogo">Agradecemos que quiera formar parte de nuestra boda, pero lamentablemente no está invitado en esta ocasión.!.</p>
            </div>
        </div>
    </div>
</template>

<style scoped>
.invitation-logo{
    background-repeat: no-repeat;
    width: 100%;
    max-height: auto;
}
.invitation-phrase{
    background-repeat: no-repeat;
    width: 100%;
    max-height: auto;
}
.invitation-crono{
    background-repeat: no-repeat;
    width: 100%;
    max-height: auto;
}
.invitation-confirma{
    background-repeat: no-repeat;
    width: 100%;
    max-height: auto;
}
.invitation-ceremonia{
    background-repeat: no-repeat;
    width: 100%;
    max-height: auto;
}

.fondo1{
    background-image: url('../assets/fondo1.png');
    background-size: 100% 100%;
}

.fondo2{
    background-image: url('../assets/fondo2.png');
    background-size: 100% 100%;
}
.fondo3{
    background-image: url('../assets/fondo3.png');
    background-size: 100% 100%;
}
.fondo4{
    background-image: url('../assets/fondo4.png');
    background-size: 100% 100%;
}
.fondo5{
    background-image: url('../assets/fondo5.png');
    background-size: 100% 100%;
}
.fondo6{
    background-image: url('../assets/fondo6.png');
    background-size: 100% 100%;
}
.fondo7{
    background-image: url('../assets/fondo7.png');
    background-size: 100% 100%;
}
.fondo8{
    background-image: url('../assets/fondo8.png');
    background-size: 100% 100%;
}
.fondo-none{
    background-image: none !important;
    background-color: white;
    background-size: 100% 100%;
}

.form-control{
    border: 1px solid rgba(#C49988, 0.4);
    width: 100%;
    padding: 10px;
}

.btn-mapa{
    text-decoration: none;
    cursor: pointer;
    background-color: rgb(128, 84, 30);
    padding: 15px 25px;
    border-radius: 10px;
    color: white;
    margin: 15px;
}
.btn-mapa:hover{
    background-color: rgb(56, 31, 1);
    color: white;
}

.text-color{
    color: #C49988;
}
.text-font{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.text-font2{
    font-family: 'Great Vibes', regular;
}
.line{
    background-color: #C49988;
    width: 2px;
    height: 50px;
    opacity: 0.5;
}

.logo{
    margin-top: 1em;
}

.title-logo{
    color: #C49988;
    font-family: 'Great Vibes', regular;
    margin-bottom: 5px !important;
}

.text-date{
    color: #C49988;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.cronometro {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    max-width: 350px;
    color: white;
    border: 1px solid #C49988;
    margin: 0 auto;
    border-radius: 20px;
    background-color: #C49988;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}



.text-color-dark{
    color: #ffffff !important;
}

.cronometro-dark {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    max-width: 350px;
    color: white;
    border: 1px solid #C49988;
    margin: 0 auto;
    border-radius: 20px;
    background-color: #f0c8b8;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.tiempo {
    display: flex;
    align-items: center;
}

.dia,
.hora,
.minuto,
.segundo {
    margin: 0 2px;
    display: flex;
    flex-direction: column;
    margin: 15px;
}

.dia:before,
.hora:before,
.minuto:before,
.segundo:before {
    content: "";
}

.dia:after,
.hora:after,
.minuto:after,
.segundo:after {
    content: "";
}

span {
    font-weight: bold;
}
/* Estilo para el tag seleccionado */
.multiselect__tag {
    background-color: rgb(128, 84, 30) !important; /* Verde */
    color: #fff; /* Texto blanco */
}

/* Estilo para el botón de eliminación en el tag */
.multiselect__tag-icon {
    color: #fff; /* Icono blanco */
}

/* Estilo para los tags desplegados */
.multiselect__tags {
    border: 1px solid rgb(128, 84, 30) !important; /* Borde verde */
}

/* Estilo para el input del multiselect */
.multiselect__input {
    border: 1px solid rgb(128, 84, 30); /* Borde verde */
}
.multiselect__option--highlight {
    background-color: rgb(128, 84, 30) !important;
}
.multiselect__option--selected {
    background-color: rgb(128, 84, 30) !important;
}
</style>